// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-maxovation-js": () => import("./../src/pages/privacy-policy-maxovation.js" /* webpackChunkName: "component---src-pages-privacy-policy-maxovation-js" */),
  "component---src-pages-privacy-policy-rivalwheels-js": () => import("./../src/pages/privacy-policy-rivalwheels.js" /* webpackChunkName: "component---src-pages-privacy-policy-rivalwheels-js" */)
}

